import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { GetAllPlansModel, GetPlanByIdModel, PlansModel } from '../models';
import { PlansService } from '../services/plans.service';
import { PlansAction } from './plans.actions';
import { catchError, tap, throwError } from 'rxjs';
import { TableData } from '@carwash-project/modules/ui';

export const PLANS_STATE_TOKEN = new StateToken<PlansStateModel>('plans');

export interface PlansStateModel {
  plans: GetAllPlansModel.Response | null;
  plan: GetPlanByIdModel.Response | null;
  planSelect: PlansModel.Plan | null;
  branchSelect: number | null;
  loading: boolean;
  error: unknown;
}

const initialState: PlansStateModel = {
  plans: null,
  plan: null,
  planSelect: null,
  branchSelect:null,
  loading: false,
  error: null,
};

@State<PlansStateModel>({
  name: PLANS_STATE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class PlansState {
  private readonly plansService = inject(PlansService);

  @Selector()
  public static plans(state: PlansStateModel) {
    return state.plans;
  }

  @Selector()
  public static plansTable(state: PlansStateModel) {
    return new TableData(state.plans?.results ?? [], state.plans?.totalCount);
  }

  @Selector()
  public static plan(state: PlansStateModel) {
    return state.plan;
  }

  @Selector()
  public static loading(state: PlansStateModel) {
    return state.loading;
  }

  @Selector()
  public static planSelect(state: PlansStateModel) {
    return state.planSelect;
  }

  @Selector()
  public static branchSelect(state: PlansStateModel) {
    return state.branchSelect;
  }

  /** Sync */
  @Action(PlansAction.Select)
  public onPlanSelect(
    ctx: StateContext<PlansStateModel>,
    { payload, branch }: PlansAction.Select
  ) {
    ctx.patchState({
      planSelect: payload,
      branchSelect:branch
    });
  }

  /** Action Handle Error */
  @Action(PlansAction.Failure)
  public handleError(
    ctx: StateContext<PlansStateModel>,
    { error }: PlansAction.Failure
  ) {
    ctx.patchState({
      error,
      loading: false,
    });
    return throwError(() => error);
  }

  /** Async */
  @Action(PlansAction.List)
  public getAllPlans(
    ctx: StateContext<PlansStateModel>,
    { query }: PlansAction.List
  ) {
    ctx.patchState({ loading: true });
    return this.plansService.getAllPlans(query).pipe(
      tap((payload) => {
        ctx.patchState({
          plans: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new PlansAction.Failure(error)))
    );
  }

  @Action(PlansAction.Create)
  public createPlan(
    ctx: StateContext<PlansStateModel>,
    { body }: PlansAction.Create
  ) {
    return this.plansService
      .createPlans(body)
      .pipe(
        catchError((error) => ctx.dispatch(new PlansAction.Failure(error)))
      );
  }

  @Action(PlansAction.Detail)
  public getPlanById(
    ctx: StateContext<PlansStateModel>,
    { planId }: PlansAction.Detail
  ) {
    return this.plansService.getPlanById(planId).pipe(
      tap((payload) => {
        ctx.patchState({
          plan: payload,
        });
      })
    );
  }

  @Action(PlansAction.Update)
  public updatePlan(
    ctx: StateContext<PlansStateModel>,
    { body, planId }: PlansAction.Update
  ) {
    return this.plansService
      .updatePlan(body, planId)
      .pipe(
        catchError((error) => ctx.dispatch(new PlansAction.Failure(error)))
      );
  }
}
