import {
  CreatePlanModel,
  GetAllPlansModel,
  PlansModel,
  UpdatePlanModel,
} from '../models';

export namespace PlansAction {
  export class List {
    static readonly type = '[Plans] Get all plans';
    constructor(public query: GetAllPlansModel.Request | null) {}
  }

  export class Create {
    static readonly type = '[Plans] Create plan';
    constructor(public body: CreatePlanModel.Request) {}
  }

  export class Detail {
    static readonly type = '[Plans] Get plan by Id';
    constructor(public planId: number | string) {}
  }

  export class Update {
    static readonly type = '[Plans] Update Plan';
    constructor(
      public body: UpdatePlanModel.Request,
      public planId: number | string
    ) {}
  }

  export class Select {
    static readonly type = '[Plans] Load current plan selected';
    constructor(
      public payload: PlansModel.Plan,
      public branch: number | null = null
    ) {}
  }

  export class Failure {
    static readonly type = '[Plans] Failure';
    constructor(public error: unknown) {}
  }
}
